#page-bar {
    .content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px $default-margin;
    }

    .others {
        display: none;
        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            button {
                white-space: normal;
            }
        }
    }

    .actions {
        .action {
            font-size: 1.4em;
            cursor: pointer;
        }
    }
}
