.burger {
    $strip-height: 5px;
    $space-between: 1.5 * $strip-height;

    width: $strip-height * 8;
    height: (3 * $strip-height + 2 * $space-between);
    position: relative;
    //margin: 50px auto;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;

    span {
        display: block;
        position: absolute;
        height: $strip-height;
        width: 100%;
        background: $primary-color;
        border-radius: $strip-height;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: .25s ease-in-out;

        &:nth-child(1) {
            top: 0;
        }
        &:nth-child(2), &:nth-child(3) {
            top: $strip-height + $space-between;
        }
        &:nth-child(4) {
            top: $strip-height * 2 + $space-between * 2;
        }
    }

    &.open {
        span {
            &:nth-child(1) {
                top: $strip-height + $space-between;
                width: 0;
                left: 50%;
            }

            &:nth-child(2) {
                transform: rotate(45deg);
            }

            &:nth-child(3) {
                transform: rotate(-45deg);
            }

            &:nth-child(4) {
                top: $strip-height * 2;
                width: 0;
                left: 50%;
            }
        }
    }
}
