.portlet-table {
    &.light {
        padding: 0 $default-portlet-margin;
        margin: 0;

        .portlet-body {
            padding: 0;
            overflow: auto;
        }
    }

    &.table-extended {
        padding: 0 !important;

        table {
            td:first-child, th:first-child {
                padding-left: $default-portlet-margin;
            }
            td:last-child, th:last-child {
                padding-right: $default-portlet-margin;
            }
        }
    }
}

table.table {
    margin: 0;

    tr {
        td, th {
            //height: $table-cell-height;
            vertical-align: middle;

            &:first-child {
                padding-left: 0;
                text-align: left;
            }

            .documentType {
                font-weight: bold;
            }
        }

        th {
            color: $primary-color;
            font-weight: normal;
            border-bottom-width: 1px;
        }

        &.pinned {

            color: #fff;
            background: $primary-color;
            text-transform: uppercase;
        }

        td {
            .grid-row-actions {
                .edit-btn, .delete-btn {
                    font-size: 28px;
                    color: $primary-color;
                }
            }
        }
    }

    .icon {
        font-size: $table-icon-height;
    }
}
