/*
 * Row with equal height columns
 * --------------------------------------------------
 */
[class*="row-eq-height"] {
    flex-wrap: wrap;

    &.height-center {
        align-items: center;
    }

    [class*="col-"] {
        display: flex;
        flex-direction: column;
        flex: 1;

        & > div {
            flex-grow: 1;
        }
    }
}

.row-eq-height {
    display: flex;
}

@media (min-width: $screen-xs-min) { /* 991px */
    .row-eq-height-xs {
        display: flex;
    }
}

@media (min-width: $screen-sm-min) { /* 991px */
    .row-eq-height-sm {
        display: flex;
    }
}

@media (min-width: $screen-md-min) { /* 991px */
    .row-eq-height-md {
        display: flex;
    }
}
