form.transparent {
  display: flex;
  flex-direction: column;
  gap: 20px;

  label {
    font-size: 1.2rem;
    margin-bottom: 3px
  }

  a {
    color: $text-light-color;
    text-decoration: underline;
  }

  .field {
    label {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .input {
      width: 100%;
    }
  }

  button[type="submit"] {
    font-size: 1.2rem;
    width: 200px;
    align-self: center;
  }

  input[type="text"], input[type="password"], input[type="email"] {
    background: transparent;
    border-radius: 3px !important;
    height: 40px;
    font-size: 1.2rem;
    padding: 0 1rem;
    color: $text-light-color;
    caret-color: $text-light-color;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active{
      transition: background-color 5000s ease-in-out 0s;
    }

    &:-webkit-autofill{
      -webkit-text-fill-color: $light-color !important;
    }

    border: 2px solid rgba($light-color, 0.5);

    &:active, &:focus {
      border: 2px solid $light-color;
      outline: none;
    }
  }
}
