.cc-cookies {
    top:auto;
    bottom:0;
    width: 100%;
    a.cc-cookie-accept, .cc-cookies-error a.cc-cookie-accept, .cc-cookies a.cc-cookie-decline, .cc-cookies-error a.cc-cookie-decline, .cc-cookies a.cc-cookie-reset
    .cc-cookie-accept {
        margin-left: 15px;
    }
}

.cc-cookies, .cc-cookies-error {
    a {
        &.cc-cookie-accept, &.cc-cookie-decline, &.cc-cookie-reset {
            background-color: $primary-color;
        }
        &:hover {
            &.cc-cookie-accept, &.cc-cookie-decline, &.cc-cookie-reset {
                background-color: $bg-default-hover;
            }
        }
    }
}
