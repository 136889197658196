//##  Default Layout Settings
//
// Define layout element width and height.

// Header
$header-height:             				            				0px  !default; // header height on table and desktop view. default: 42px
$header-height-xs:             											80px !default; // header height on mobile view

$header-logo-height:                                    				14px;
$header-menu-toggler-width:                             				24px;
$header-menu-toggler-height:                            				24px;
$header-sidebar-toggler-margin:                         				($header-height - $header-menu-toggler-height) / 2 0 0 0 !default;
$header-responsive-toggler-margin:                      				($header-height - $header-menu-toggler-height) / 2 6px 0 6px !default;
$header-logo-margin:                                					($header-height - $header-logo-height) / 2 0 0 0 !default;  // adjsut logo's margin top. default: -1px

$header-top-menu-general-item-padding:      							17px 10px 9px 10px !default;  // adjust top menu general elements padding. default: 15px 10px 7px 10px
$header-top-menu-general-item-padding-on-mobile:       					17px 6px 9px 6px !default;  // adjust top menu general elements padding mobile. default: 15px 10px 7px 10px
$header-top-menu-user-item-padding:         				  			14px 6px 12px 8px !default; // adjust top menu user bar element padding. default: 6px 0px 6px 6px
$header-top-menu-user-item-padding-on-mobile:         					14px 0px 12px 2px !default;  // adjust top menu user bar element padding on mobile. default: 6px 0px 6px 4px
$header-top-menu-language-item-padding:     							14px 3px 12px 7px !default;  // adjust top menu language bar element padding. default: 11px 1px 11px 5px
$header-top-menu-language-item-padding-on-mobile:						14px 4px 12px 2px !default;  // adjust top menu language bar element padding. default: 11px 0px 11px 4px
$header-top-menu-general-item-badge-top:                				10px !default;  // adjust top menu general elements badge top position. default: 8px
$header-top-menu-icon-font-size:                                        17px !default;

$header-top-menu-user-font-size:                                        13px !default;
$header-top-menu-user-font-weight:                                      300 !default;
$header-top-menu-user-dropdown-link-font-size:                          14px !default;
$header-top-menu-user-dropdown-link-font-weight:                        300 !default;

$header-hor-menu-general-item-padding:     								13px 13px !default;  // adjust hor menu elements padding top. default: 11px 10px
$header-hor-menu-submenu-item-padding: 				 					9px 10px !default;  // adjust hor menu elements padding top. default: 7px 10px
$header-hor-menu-submenu-item-arrow-top:                				9px;  // adjust hor menu dropdown menu's arrow. default: 9px
$header-hor-menu-search-toggler-button-padding:      					15px 22px 15px 22px !default;  // adjust hor menu elements padding top. default: 13px 22px 11px 22px
$header-hor-menu-search-button-padding:     		 					7px 20px !default;  // adjust hor menu elements padding top. default: 5px 20px
$header-hor-menu-search-input-margin:    			 					7px 0 0 0 !default;  // adjust hor menu elements padding top. default: 5px 0 0 0

// Horizontal menu font
$header-hor-menu-default-link-font-size:                                14px !default;
$header-hor-menu-default-link-font-weight:                              400 !default;
$header-hor-menu-dropdown-menu-link-font-size:                          14px !default;
$header-hor-menu-dropdown-menu-link-font-weight:                        300 !default;
$header-hor-menu-mega-menu-header-font-size:                            15px !default;
$header-hor-menu-mega-menu-header-font-weight:                          400 !default;

// Sidebar
$sidebar-width:             											235px !default; // sidebar width. default:235px
$sidebar-collapsed-width:   											45px  !default; // minimized/collapsed sidebar width. default:35px
$sidebar-collapsed-submenu-width-on-hover:								210px !default; // sub menu width displayed on hover in collapsed sidebar mode. default:210px
$sidebar-logo-container-width:  										$sidebar-width !default; // logo container width. Normally same with sidebar-width.
$sidebar-logo-container-width-xs: 										110px !default; // logo container width in mobile portrait view. default:110px.
$sidebar-logo-container-width-xxs: 										100px !default; // logo container width in mobile landspace view. default:100px.

$sidebar-menu-head-font-size:                                           14px !default;
$sidebar-menu-link-font-size:                                           14px !default;
$sidebar-menu-link-font-weight:                                         300  !default;
$sidebar-menu-link-icon-font-size:                                      16px !default;
$sidebar-menu-sub-menu-link-icon-font-size:                             14px !default;

// Page content
$page-content-min-height:                   							600px !default; // default page content's min height. default:600px.

// Page footer
$page-footer-height:                                                    33px !default;

// Z-index master list
$zindex-header-fixed:                                                   9995 !default;
$zindex-header-static:                                                  9995 !default;
$zindex-quick-sidebar:                                                  10500 !default;
$zindex-quick-sidebar-full-height:										9996 !default;
$zindex-sidebar-fixed:     												10000 !default;
$zindex-footer-fixed:      												10000 !default;
$zindex-sidebar-submenu:   												2000 !default;
$zindex-go-to-top:   												    10001 !default;
