.recordVisualization {
    &-actions {
        a, button {
            background: transparent;
            border: none;
            color: $primary-color;
            font-size: 33px;
            padding: 0 3px;
            display: inline-block;
        }
    }

    &-infos {
        width: 100%;
        max-width: 500px;
        float:right;
        margin-bottom: $default-margin/2;

        &-status {
            font-size: 18px;
        }

        &-referent {
            min-width: 50%;
            label, select {
                display: inline-block;
                max-width: 50%;
            }
            label{
                text-align: right;
                width:140px;
                padding-right:10px;
                margin-bottom: 0;
                line-height: 35px;
            }
            .form-group{
                width: calc(100% - 140px);
                float: right;
            }

        }
    }

    &-property {
        margin: $default-margin;

        &-title {
            font-weight: 400;
            margin-left: 15px;
        }
    }

    &-applicants {
        margin-bottom: $default-margin;

        &-title {
            color: $primary-color;
            font-size: 18px;
            font-weight: 400;
            margin-bottom: $default-margin;
        }

        table {
            thead {
                tr {
                    th {
                        font-size: 16px;
                    }
                }
            }
            tfoot {
                text-align: right;

                tr {
                    td {
                        font-size: 18px;
                        padding: 8px !important;

                        .amount {
                            font-weight: bold;
                            min-width: 150px;
                            display: inline-block;
                            padding-right: 5px;
                        }
                    }
                }
            }
        }
    }
}

.record-comments {
    table.comments {
        tr {
            td, th {
                border-color: $primary-color;
            }
        }
    }
}
