body:not(.minimalist) {
    header {
        display: none;
        z-index:999;

        @media (max-width: $screen-sm-max) {
            position: fixed;
            top: 0;
            background: $bg-color;
            width: 100%;
            height: $header-height-xs;
            z-index: 999;

            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: $default-margin/2 $default-margin;
            .logo{
                .page-logo {
                    height: 50px;
                    img {
                        height: 100%;
                    }
                }
            }
        }
    }

    @media (max-width: $screen-sm-max) {
        .page-container {
            padding-top: $header-height-xs !important;
        }
    }
}
body{
    header{
        z-index:999;
        .container{
            padding:0;
            width:100%;
            max-width: 1200px;
            margin:0 auto;

            @media (max-width: $screen-sm-max) {
                padding:0 15px;
            }
        }
        .logo{
            text-align: center;
            .page-logo {
                display: inline-block;
            }
        }
        .header-content{
            padding:0;
        }
        .page-logo {
            a {
                display: block;

                img {
                    display: block;

                    @media (max-width: $screen-sm-max) {
                        height: 50px;
                    }
                }

                .logo-gtf-white {
                    display: none;
                }
            }
        }
    }
}
