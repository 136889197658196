#statusActivityChart {
    min-width: 400px;
    height: 600px;
    margin: 0 auto;
}

#period {
    .select2-container {
        width: 120px;
        text-align: center;
        margin-left: 10px;
    }
}
