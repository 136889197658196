.btn {
  &--transparent {
    border: 1px solid $light-color;
    transition: all 0.3s;
    color: $text-light-color;

    &:hover {
      background-color: $light-color;
      color: $primary-color;
    }
  }
}
