@import "../../vendor/metronic/sass/bootstrap/variables";

.grid-search-title {
    .collapsed {
        i {
            transform: rotate(90deg);
        }
    }
}

.grid-search {
    margin: 0 $default-margin $default-margin;

    .filter-inputs {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        margin-left: -($default-margin / 2);
        margin-right: -($default-margin / 2);

        .filter-input {
            flex-basis: 25%;
            min-width: 310px;
            flex-grow: 1;
            padding: 4px $default-margin / 2;

            .grid-filter-input {
                display: flex;
                flex-wrap: wrap;
                margin-left: -($default-margin / 2);
                margin-right: -($default-margin / 2);


                > * {
                    flex: 1 1 auto;
                    padding: 0 $default-margin / 2;

                    &.grid-filter-operator {
                        flex-basis: 30%;
                    }
                }
            }
        }
    }

    .grid-search-action {
        text-align: right;
    }
}

.grid {

    .grid_header {
        margin-bottom: 10px;

        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .mass-actions {
            flex-grow: 1;
        }

        .exports {
            margin-left: 10px;
        }
    }

    .mass-actions {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .total {
            color: $brand-main-color;
            display: inline-block;
            margin-right: 6px;
        }

        .grid_massactions {
            display: flex;

            .btn {
                margin-left: 10px;
                flex-shrink: 0;
            }
        }

        @media (max-width: $screen-xs-min) {
            .grid_massactions_helper {
                width: 100%;
            }

            .grid_massactions {
                width: 100%;
                .btn {
                    margin-left: 0;
                }
            }
        }
    }

    .align-center {
        text-align: center;
    }

    .grid_body {
        th.sortable {
            background-image: url(../images/sort_both.png);
            background-repeat: no-repeat;
            background-position: right center;
            padding-right: 30px;

            &.asc {
                background-image: url(../images/sort_asc.png);
            }
            &.desc {
                background-image: url(../images/sort_desc.png);
            }
        }

        .grid-row-actions {
            margin: 0;
            padding: 0;
            list-style-type: none;

            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
        }

        .grid-row-titles {
            th {
                color: $brand-main-font-color;
                font-weight: bold;
                border-color: lighten($brand-main-font-color, 30%);
            }
        }

        .grid-row-cells {
            td {
                border-bottom: 1px solid lighten($brand-main-font-color, 30%);
            }
        }

        [class^=grid_boolean] {
            display: inline-block;
            min-width: 40px;
        }
    }

    .grid_boolean_Oui {
        background-color: $brand-secondary-color;
    }

    .grid_boolean_Non {
        background-color: $brand-main-color;
    }
}

.grid_footer {
    .dataTables_paginate {
        text-align: right;

        .pagination {
            margin: 0;
        }
    }
}

.table-checkable tr > th:first-child, .table-checkable tr > td:first-child {
    max-width: 100%;
}
