@media (max-width: $screen-xs-max) {
    body {
        font-size: 12px;
    }
}

.icon {
    font-size: 36px;
    vertical-align: middle;
    color: $brand-main-font-color;

    &:hover {
        color: darken($brand-main-font-color, 20%);
    }

    &-flip-horizontal {
        transform: rotate(180deg);
    }

    &-flip-right {
        transform: rotate(-90deg);
    }

    &-flip-left {
        transform: rotate(90deg);
    }
}

a {
    color: $o-link-color;
    &:hover {
        color: $o-link-hover-color;
        text-decoration: none;
    }
}

.page-actions {
    padding: 12px 0;
}

.alert {
    .help-block {
        margin: 0;

        .list-unstyled {
            margin: 0;
        }
    }
}


footer {
    color: darken(#fff, 50%);
    font-size: 11px;
    padding: 10px 0;
    text-align: center;

    ul {
        display: inline-block;
        padding: 0;

        li {
            display: inline-block;

            &:not(:last-child):after {
                content: ' - ';
            }

            a {
                color: darken(#fff, 50%);

                &:hover, &:active, &:focus {
                    color: $primary-color;
                }
            }
        }
    }
}

.input-group {
    &-addon {
        background: #fff;
    }
}


.btn{
    &.bg-default{
        transition:all 0.4s ease-in-out;
        background-color: $primary-color !important;
    }
    &:hover{
        &.bg-default{
            background-color: $bg-default-hover !important;
        }
    }
    &.default{
        &.date-set, &.date-set:hover{
            background-color: white;
            border-color:#c2cad8;
            border-width: 1px 1px 1px 0 !important;
            padding-top: 8px;
            padding-bottom: 4px;
        }
    }
}
.has-success{
    .btn{
        &.default{
            &.date-set, &.date-set:hover{
                border-color:#27a4b0;
            }
        }
    }
}
.tooltip-inner{
    min-width: 200px;
    max-width: 300px;
}

input[type='date'] {
    -moz-appearance:textfield;
    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button,  &::-webkit-clear-button, &::-webkit-calendar-picker-indicator {
        -webkit-appearance: none;
        display: none;
    }
}
.intro{
    margin:15px 0 30px;
}
