.recordForm {
    .propertyBlock-content-picture{
        height:178px;
    }

    &-portlet {
        padding-bottom: 0 !important;
    }

    &-personForm {
        margin-top: 35px;

        h2, h3, h4, h5 {
            color: $primary-color;

            &.no-garant {
                margin-bottom: 20px;
                font-weight: bold;
                color: #e73d4a;
            }
        }

        .form-group {
            .radio {
                margin: 0;
                width: 15%;
                min-width:110px;

                label {
                    padding-left: 25px;

                    input {
                        margin-top: 0;
                        margin-left: -25px;
                    }
                }
            }
        }

        &-documents {
            h4 {
                position: relative;

                em {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    font-size: 0.8em;
                    color: $primary-color;

                    @media (max-width: $screen-sm-max) {
                        position:relative;
                        display: block;
                        right:auto
                    }
                }
            }

            h5.portlet.portlet-title {
                margin:13px 0 0;
                &:first-child{
                    margin-top:0;
                }

                .form-group {
                    max-width: 50%;
                    float: right;
                    font-size: 16px;
                    color: $primary-color;

                    @media (max-width: $screen-sm-max) {
                        max-width: 80%;
                        float: none;
                        margin: 10px 0 0;
                    }

                    .checkbox {
                        margin: 0;
                        input {
                            margin: 0;
                            vertical-align: bottom;
                            position: static;
                        }
                    }
                }

                &.toggleSlide {
                    .arrow {
                        transform: rotate(180deg);
                        transition: all 0.3s ease-in-out;
                    }

                    &.active {
                        .arrow {
                            margin-left: $default-margin;
                            transform: rotate(0deg);
                        }
                    }
                }

                .notEmpty-picto {
                    display: none;
                }

                &.notEmpty {
                    .notEmpty-picto {
                        display: inline;
                    }
                }
            }
            .portler-body{
                overflow: hidden;
                width:100%;
                .fileIntro{
                    color:#444;
                    padding:20px 0 20px;
                    margin:0 30px
                }

            }
            .document {
                .dropzone {
                    margin-bottom: 15px;
                    margin-top:0;

                    .more-upload {
                        display: none;
                    }
                }
            }

            &-comment {
                margin-top: 25px;
            }
        }

        &-footer {
            .btn {
                margin-left: 10px;
                @media (max-width: $screen-sm-max) {
                    margin-bottom: 15px
                }
            }
        }
    }

    .processus-candidature {
        color: $text-color;

        h2 {
            text-align: center;
            font-size: 20px;
            color: #0056b3;
            margin-bottom: 20px;
            font-weight: bold;
        }

        .etapes {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .etape {
            display: flex;
            align-items: center;
            gap: 10px; /* Ajoute un espace entre l'icône et le texte */
            text-align: left;

            i {
                font-size: 24px;
                margin-bottom: 8px;
                display: block;
            }

            h3 {
                margin: 0;
                font-size: 16px;
                font-weight: 500;
            }

            &.final {
                display: block;
                text-align: center;
                padding: 20px 50px;
                border: 2px solid $primary-color;
                border-radius: 10px !important;

                p {
                    margin: 0;
                    font-size: 16px;
                }
            }
        }

        .chevron {
            margin: 0 50px;
            font-size: 36px;
            font-weight: bold;
            color: $primary-color;
        }

        .actions {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 10px;
            gap: 10px;

            p {
                margin: 0;
                font-size: 14px;
            }

            span {
                font-size: 36px;
                font-weight: bold;
                margin-left: 50px;
                margin-right: 50px;
            }
        }
    }
}

.select2-drop {
    .select2-results {
        max-height: 300px;
    }
}
