.dropzone {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;

    border: 2px dashed lighten($brand-main-font-color, 20%);
    background: white;
    padding: 20px;
    text-align: center;
    margin: $default-margin;

    @media (max-width: $screen-sm-min) {
        width: auto;
    }

    .dz-default.dz-message [class^='icon-'] {
        font-size: 40px;
        margin-top: $default-margin;
    }

    .dz-preview {
        width: 150px;
        height: 80px;

        &.dz-error {
            background: $color-danger;

            img {
                opacity: 0.5;
                filter: blur(3px);
            }

            .dz-error-message {
                top: 0;
                left: 0;
                opacity: 0.5;
                width: 100%;
                height: 100%;
                display: block;

                &::after {
                    border: 0;
                    height: 0;
                    width: 0;
                }

                span {
                    display: block;
                    width: 100%;
                }
            }
        }

        .dz-details {
            opacity: 1;
            position: static;
            height: 100%;
            padding: 0;

            img {
                margin: auto;
                object-fit: cover;
                height: 100%;
            }

            .dz-actions {
                height: 0;
                overflow: hidden;
                transition-duration: 0.5s;
                position: absolute;
                bottom: 0;
                left: 0;
                display: flex;
                justify-content: space-around;
                align-items: center;
                background: rgba(0, 0, 0, 0.3);
                width: 100%;

                .docs-buttons {
                    user-select: none;

                    .dz-external-preview, .dz-delete, .docs-tooltip {
                        color: #fff;
                        text-align: center;
                        cursor: pointer;
                        font-size: 20px;

                        i {
                            cursor: pointer;
                            color: #FFF;
                        }

                        & + .tooltip > .tooltip-inner {
                            font-size: 10px;
                        }
                    }

                    .dz-external-preview, .docs-tooltip {
                        i {
                            &:hover {
                                color: $brand-main-color;
                            }
                        }
                    }

                    .dz-delete {
                        i {
                            &:hover {
                                color: $color-danger-font;
                            }
                        }
                    }
                }
            }

            &:hover {
                .dz-actions {
                    transition-duration: 0.5s;
                    height: 100%;
                }
            }
        }
    }
}
