.balance {
    &.prefixed {
        font-weight: bold;
        white-space: nowrap;
        position: relative;
        text-indent: 26px;

        &:before {
            position: absolute;
            top: 50%;
            margin-top: -10px;
            left: 0;
            text-indent: 0;

            text-align: center;
            color: #fff;
            font-weight: bold;

            display: block;
            width: 20px;
            height: 20px;
            font-size: 14px;
        }

        &.debit:before {
            content: "-";
            background: $balance-debit-font-color;
        }
        &.credit:before {
            content: "+";
            background: $balance-credit-font-color;
        }
    }
}
