@import 'colors_variables';
//##  Flat UI Color Variations

/***
Custom color buttons
***/

@each $name, $colors in $component-colors {
    .bg-#{$name} {
        background: map-get($colors, base) !important;
    }

    .bg-hover-#{$name}:hover {
        background: map-get($colors, base) !important;
    }

    .font-#{$name} {
        color: map-get($colors, base) !important;
    }

    .bg-font-#{$name} {
        color: map-get($colors, font) !important;
    }

    .border-#{$name} {
        border-color: map-get($colors, base) !important;
    }

    .border-top-#{$name} {
        border-top-color: map-get($colors, base) !important;
    }

    .border-bottom-#{$name} {
        border-bottom-color: map-get($colors, base) !important;
    }

    .border-left-#{$name} {
        border-left-color: map-get($colors, base) !important;
    }

    .border-right-#{$name} {
        border-right-color: map-get($colors, base) !important;
    }
}
