@import 'register';
@import 'signin';

body.auth-base {
  background: $background-light-color;

  > .wrapper {
    padding: 0;

    .auth-wrapper {
      display: flex;
      gap: 110px;

      @include below(lg) {
        gap: 60px;
      }

      @include below(md) {
        flex-direction: column;
        gap: 50px;
      }

      > * {
        flex: 1;
      }

      @include above(md) {
        max-width: 1020px;
      }

      .errors {
        .alert-danger {
          background-color: rgba($alert-danger-bg, 0.85);
        }
      }

      .info {
        flex: 1;
        margin-top: 19vh;

        @include below(md) {
          margin-top: 55px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          gap: 30px;

          .main, .features {
            flex-basis: calc(50% - 15px);
          }
        }

        @include below(sm) {
          .main, .features {
            flex-basis: 100%;
          }
        }

        > .logo {
          img {
            width: 100px;
          }

          @include below(md) {
            flex-basis: 100%;
            text-align: center;

            img {
              width: auto;
            }
          }
        }

        .main {
          @include below(sm) {
            text-align: center;
          }

          h2.title {
            font-size: 2.3rem;
          }

          .description {
            font-size: 1.5rem;

            @include above(sm) {
              max-width: 300px;
            }
          }
        }

        .features {
          display: inline-flex;
          gap: 15px;

          @include above(md) {
            margin-top: 45px;
          }

          @include below(md) {
            flex-wrap: wrap;
          }

          @include below(sm) {
            justify-content: center;
          }

          > * {
            padding: 20px 10px;
            text-align: center;
            border-radius: 15px !important;
            box-shadow: 0 0 15px 6px rgba($shadow-color, 0.5);
            width: 120px;
            height: 120px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            font-size: 1.1rem;
            color: $text-medium-color;

            i:before {
              font-size: 5rem;
            }
          }
        }

        .links {
          margin-top: 80px;

          @include below(md) {
            flex-basis: 100%;
            margin-top: 30px;
          }

          .link {
            span {
              font-size: 1.1rem;
            }

            a {
              color: $text-dark-color;
              display: block;
              font-size: 1.6rem;
              font-weight: bold;
              position: relative;

              &:after {
                @include icon($icon-arrow);
                position: absolute;
                right: 0;
                transition: transform 0.3s;
                font-size: 1.9rem;
              }

              &:hover:after {
                transform: translateX(50%);
              }
            }
          }
        }
      }
    }
  }
}
