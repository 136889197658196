.adminAuthentication {
  header {
    min-height: 100px !important;
    .container {
      display: block;
      .logo{
        margin:0 auto;
        float: none;
      }
      a img {
        max-height: 100px !important;
      }
    }
  }
  .wrapper{
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .container {
        display: flex;
        justify-content: center;

        .alert {
          max-width: 440px;
          width: 100%;
        }
    }
  }
  .admin-authentication-container{
    text-align: center;
    padding: 45px 70px 45px;
    background-color: transparentize(#eeeeee, 0.3);
    width:90%;
    max-width: 440px;
    margin:0 auto;
    h2{
      margin:0 0 35px;
    }
    .form-group{
      width:100%;
      .input-group{
        width:100%;
        input{
          width:100%;
        }
        .input-icon.right > i{
          left:105%;
        }
      }
    }
    .passwordLost{
      display: block;
      width:100%;
      padding-top:15px;
      margin-bottom:40px;
      color:#333;
      &:hover{
        text-decoration:underline;
      }
    }
    button{
      width:100%;
    }
  }
}
