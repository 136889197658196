.propertySearch {
    &-form {
        margin-bottom: 60px;

        .control-label, .input-group {
            float: none;
            width: 100%;
            text-align: left;
        }

        &-field, &-submit {
            display: inline-block;
            vertical-align: bottom;
            float: none;
            padding-right: 0;
        }
        .btn{
            /*text-transform:none !important;*/
            padding: 6px 30px;
        }
    }

    &-description {

        &-content {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin-bottom: 15px;
            color: $color-danger-font;
            font-weight: bold;

            span {
                padding: 5px 10px;
            }
        }
        textarea {
            margin: 15px 0 10px;
        }
    }
}
