/* Generated by Glyphter (http://www.glyphter.com) on  Thu Apr 13 2017*/
@font-face {
    font-family: 'gtf';
    src: url('/fonts/gtf/gtf.woff') format('woff'),
    url('/fonts/gtf/gtf.ttf') format('truetype'),
    url('/fonts/gtf/gtf.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^='icon-']:before {
    display: inline-block;
    font-family: 'gtf';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

$icon-check: "\0041";
$icon-contact: "\0042";
$icon-delete: "\0043";
$icon-detail: "\0044";
$icon-download: "\0045";
$icon-edit: "\0046";
$icon-export: "\0047";
$icon-print: "\0048";
$icon-save: "\0049";
$icon-secure: "\004a";
$icon-time: "\004b";
$icon-avatar: "\004c";
$icon-calendar: "\004d";
$icon-warning: "\004e";
$icon-contact2: "\004f";
$icon-graph: "\0050";
$icon-folder: "\0051";
$icon-stats: "\0052";
$icon-office: "\0053";
$icon-user: "\0054";
$icon-publish: "\0055";
$icon-filetype-doc: "\0056";
$icon-filetype-jpg: "\0057";
$icon-filetype-pdf: "\0058";
$icon-filetype-ppt: "\0059";
$icon-filetype-svg: "\005a";
$icon-filetype-txt: "\0061";
$icon-filetype-xls: "\0062";
$icon-filetype-zip: "\0063";
$icon-pin: "\0064";
$icon-unpin: "\0065";
$icon-unpublish: "\0066";
$icon-file: "\0067";
$icon-48h: "\0068";
$icon-abort-1: "\0069";
$icon-abort-2: "\006a";
$icon-files: "\006b";
$icon-lock: "\006c";
$icon-time: "\006d";
$icon-arrow: "\ea3c";
$icon-chevron: "\e900";

.icon-check:before {
    content: $icon-check;
}

.icon-contact:before {
    content: $icon-contact;
}

.icon-delete:before {
    content: $icon-delete;
}

.icon-detail:before {
    content: $icon-detail;
}

.icon-download:before {
    content: $icon-download;
}

.icon-edit:before {
    content: $icon-edit;
}

.icon-export:before {
    content: $icon-export;
}

.icon-print:before {
    content: $icon-print;
}

.icon-save:before {
    content: $icon-save;
}

.icon-secure:before {
    content: $icon-secure;
}

.icon-time:before {
    content: $icon-time;
}

.icon-avatar:before {
    content: $icon-avatar;
}

.icon-calendar:before {
    content: $icon-calendar;
}

.icon-warning:before {
    content: $icon-warning;
}

.icon-contact2:before {
    content: $icon-contact2;
}

.icon-graph:before {
    content: $icon-graph;
}

.icon-folder:before {
    content: $icon-folder;
}

.icon-stats:before {
    content: $icon-stats;
}

.icon-office:before {
    content: $icon-office;
}

.icon-user:before {
    content: $icon-user;
}

.icon-publish:before {
    content: $icon-publish;
}

.icon-filetype-doc:before {
    content: $icon-filetype-doc;
}

.icon-filetype-jpg:before {
    content: $icon-filetype-jpg;
}

.icon-filetype-pdf:before {
    content: $icon-filetype-pdf;
}

.icon-filetype-ppt:before {
    content: $icon-filetype-ppt;
}

.icon-filetype-svg:before {
    content: $icon-filetype-svg;
}

.icon-filetype-txt:before {
    content: $icon-filetype-txt;
}

.icon-filetype-xls:before {
    content: $icon-filetype-xls;
}

.icon-filetype-zip:before {
    content: $icon-filetype-zip;
}

.icon-pin:before {
    content: $icon-pin;
}

.icon-unpin:before {
    content: $icon-unpin;
}

.icon-unpublish:before {
    content: $icon-unpublish;
}

.icon-file:before {
    content: $icon-file;
}

.icon-48h:before {
    content: $icon-48h;
}

.icon-abort-1:before {
    content: $icon-abort-1;
}

.icon-abort-2:before {
    content: $icon-abort-2;
}

.icon-files:before {
    content: $icon-files;
}

.icon-lock:before {
    content: $icon-lock;
}

.icon-time:before {
    content: $icon-time;
}

.icon-arrow:before {
    content: $icon-arrow;
}

.icon-chevron:before {
    content: $icon-chevron;
}
