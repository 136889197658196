@charset "UTF-8";

@import 'variables';

@import 'metronic/layout/layout';
@import 'metronic/layout/colors';
@import 'metronic/layout/themes/gtf';

/* Fonts */
@import 'fonts/gtf';

/* Mixins */
@import 'mixins/border-left';
@import 'mixins/icons';
@import 'mixins/media-queries';
@import 'mixins/overlay';

/* Layout */
@import 'layout/alignment';
@import 'layout/browserCompatibility';
@import 'layout/colors';
@import 'layout/common';
@import 'layout/cookie';
@import 'layout/form';
@import 'layout/header';
@import 'layout/layout';
@import 'layout/minimalist';
@import 'layout/responsive';
@import 'layout/sidebar';

/* Components */
@import 'components/ajax';
@import 'components/balance';
@import 'components/bootstrap';
@import 'components/button';
@import 'components/dropzone';
@import 'components/equalHeight';
@import 'components/grid';
@import 'components/icons';
@import 'components/internalComment';
@import 'components/pace';
@import 'components/page_bar';
@import 'components/personsNav';
@import 'components/portlet';
@import 'components/select2';
@import 'components/stat';
@import 'components/table';
@import 'components/switch_ios';

/* Pages */
@import 'page/adminAuthentication';
@import 'page/authentication/base';
@import 'page/errors';
@import 'page/faq';
@import 'page/propertyBlock';
@import 'page/recordForm';
@import 'page/recordsList';
@import 'page/recordVisualization';
@import 'page/searchProperty';
@import 'page/statistics';
@import 'page/userProfile';
