.page-sidebar {
    position: fixed;
    top: $header-height;
    bottom: 0;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    z-index: 999;

    .page-logo {
        padding: $logo-padding;
        text-align: center;

        img {
            max-width: 100%;
        }
    }

    .user-identity-box {
        padding: 10px 15px;

        .user-identity-content {
            color: $sidebar-menu-link-font-color;
            font-weight: bold;
        }
    }

    .page-sidebar-menu {
        flex: 1 1 auto;
        li {
            a > i {
                color: $primary-color;
            }

            &:hover a > i {
                color: $primary-hover-color;
            }

            &.active,
            &.active.open {
                > a {
                    background: $primary-color;
                    color: $sidebar-menu-link-font-color-on-active;

                    &:hover {
                        background: $primary-color;
                    }

                    > i {
                        color: $sidebar-menu-link-icon-font-color-on-active;
                    }
                }
            }
        }
    }

    .sidebar-footer {
        //align-self: flex-end;
        order: 99;
        //position: absolute;
        //bottom: 0;
    }
}


@media (max-width: $screen-sm-max) { /* 991px */
    .page-sidebar {
        width: 100%;
        margin: 0 !important;

        top: $header-height-xs;
        overflow: hidden;
        bottom: 100%;

        &.open, &.closing {
            transition: 0.4s bottom;
            bottom: 0;
        }
        &.closing {
            bottom: 100%;
        }

        .page-logo {
            display: none;
        }
    }
}
