ul.personsNav {
    display: block;
    margin: 0;
    margin-bottom: 10px;
    padding: 0 !important;
    clear: both;
    border-bottom: 1px solid #e8e8e8;

    &.navConfirm {
        border-bottom: 0;
    }

    &--single {
        padding: 0 $default-portlet-margin !important;
    }

    li {
        display: inline-block;
        padding: 10px 22px;

        &.active {
            border-bottom: 4px solid $primary-color;
        }

        a, &.confirmPerson {
            color: $primary-color;
            font-weight:600;
            margin-bottom: 10px;
            padding-left: 0;

            &.add {
                color: #ED6B75;
                text-transform: uppercase;
                
                .add-icon {
                    color: #ED6B75;
                }
            }

            h2 {
                font-size: 20px;
                font-weight: 600;
                margin: 0;
            }
        }

        a.delete {
            color: $primary-color;
            opacity: 0;
            transition-duration: 0.5s;
        }

        &.addPerson {
            &:hover {
                border-bottom: 4px solid lighten(red, 30%);
            }
        }

        &:hover {
            &.confirmPerson {
                border-bottom: 4px solid $primary-color;
            }

            border-bottom: 4px solid $primary-color-light;

            a.delete {
                opacity: 1;
                transition-duration: 0.5s;
            }
        }
    }
}
