$dark-color: #000000;
$light-color: #ffffff;
$primary-color: #014e9c;
$primary-hover-color: #314465;
$shadow-color: #cccccc;
$text-medium-color: #777777;
$text-dark-color: #2b2c30;
$bg-default-hover: #013e7d;
$o-link-color: #337ab7;
$background-light-color: #ffffff;
$background-dark-color: #000000;

$primary-color-super-light: lighten($primary-color, 60);
$primary-color-light: lighten($primary-color, 30);
$o-link-hover-color: darken($o-link-color, 15);

$color-success: #bed9b0;
$color-success-font: #13ad2b;
$color-danger: #f29f97;
$color-danger-font: #c0392b;

$default-margin: 30px;
$default-portlet-margin: 20px;
$balance-credit-font-color: #3cc051;
$balance-debit-font-color: #ed4e2a;

$table-cell-height: 50px;
$table-icon-height: 30px;
