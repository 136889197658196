@mixin overlay($backgroundColor: rgba($background-dark-color, 0.4)) {
  position: relative;

  &:before {
    content: '';
    @extend .full-content;
    z-index: 1;
    background-color: $backgroundColor;
  }
}
