.stat {

    font-size: 1em;
    margin-bottom: $default-margin;

    &.stat-default {
        background-color: $primary-color !important;
        color: #fff;

        .more {
            background-color: darken($primary-color, 10) !important;
        }
    }

    &.stat-light {
         background-color: $primary-color-light !important;
         color: #fff;

         .more {
             background-color: darken($primary-color-light, 10) !important;
         }
     }

    &.stat-superlight {
          background-color: $primary-color-super-light !important;
          color: #fff;

          .more {
              background-color: darken($primary-color-super-light, 10) !important;
          }
      }

    @import '../metronic/layout/colors_variables';
    @each $name, $colors in $component-colors {
        &.stat-#{$name} {
            background-color: map-get($colors, base) !important;
            color: map-get($colors, font);

            .more {
                background-color: darken(map-get($colors, base), 10) !important;
            }
        }
    }

    .content {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .visual {
            width: 120px;
            text-align: center;
            font-size: 4em;
        }

        .title {
            font-size: 1.5em;
            flex: 2;
            flex-shrink: 0;
        }

        .details {
            padding: 0 $default-margin;

            .number {
                font-size: 2em;
                font-weight: bold;
            }
        }
    }

    .more {
        text-transform: uppercase;
        font-weight: 300;
        padding: 6px 10px;
        font-size: 0.8em;
        text-align: right;
    }
}


@media (max-width: $screen-xs-max) { /* 991px */
    .stat {
        .content {
            flex-direction: column;
            padding: 0 $default-margin;

            .title {
                text-align: center;
            }
        }
    }
}
