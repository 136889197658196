.recordsList {

    &-records {

        &-category {
            margin-bottom: 60px;

            &:last-child {
                margin-bottom: 10px;
            }

            &-title {
                color: $primary-color;
                font-weight: bold;
                text-transform: uppercase;
                font-size: 16px;
                margin-top: 10px;
            }
        }

        &-item {
            margin-top: 25px;
            margin-bottom: 35px;

            &-title {
                margin-bottom: 25px !important;
                font-size: 14px !important;
                color: $primary-color;
            }

            &-content {
                margin: 10px;
                justify-content: center;
                flex-wrap: nowrap;

                @media (max-width: $screen-sm-max) {
                    flex-direction: column;

                    [class^="col-md-"] {
                        float: none;
                        width: 100%;
                    }
                }

                &-actions {
                    padding-top:23px;

                    @media (max-width: $screen-sm-max) {
                        margin-top: $default-margin;
                    }

                    &-date {

                        span {
                            color: $primary-color;
                            font-weight: bold;
                        }
                    }

                    &-flag {
                        padding: 0 10px;
                        font-weight: bold;
                        margin-bottom:10px;
                        height: 40px;
                        line-height: 41px;

                        span{
                            display: inline-block;
                            line-height:1em;
                        }

                        &-icon {
                            font-size: 22px;
                            vertical-align: middle;
                            margin-right: 10px;
                            margin-top:-2px;
                        }
                    }

                    &-toComplete {
                        background: #F3D4C2;
                        color: #FE7912;
                    }

                    &-submitted {
                        background: lighten($brand-main-color, 60%);
                        color: $brand-main-color;
                    }

                    &-validated {
                        background: $color-success;
                        color: $color-success-font;
                    }

                    &-refused {
                        background: $color-danger;
                        color: $color-danger-font;
                    }

                    &-aborted {
                        background: darken($color-danger, 10%);
                        color: #fff;
                    }

                    &-unfinalized {
                        background: $color-success;
                        color: $color-danger-font;
                    }

                    &-accepted {
                        background: $color-success;
                        color: $color-success-font;
                    }

                    .spacer {
                        height: 25px;
                    }

                    &-buttons {

                        .btn {
                            white-space: normal;
                            width: calc(50% - 5px);

                            &:first-child {
                                margin-right: 5px;
                            }
                        }
                    }
                }
            }
        }
    }

    &-back {
        .grid-count {
            margin-bottom: $default-portlet-margin/3;
            padding: 5px $default-portlet-margin !important;
        }

        .edit-btn {
            font-size: 28px;
            color: $primary-color;
        }

        .status {
            &-to_complete {
                .grid-column-status_label {
                    background: #F3D4C2;
                    color: #FE7912;
                }
            }

            &-submitted {
                .grid-column-status_label {
                    background: lighten($primary-color, 60%);
                    color: $primary-color;
                }
            }

            &-completed {
                .grid-column-status_label {
                    background: lighten($primary-color, 40);
                    color: #fff;
                }
            }

            &-validated {
                .grid-column-status_label {
                    background: $color-success;
                    color: $color-success-font;
                }
            }

            &-refused {
                .grid-column-status_label {
                    background: $color-danger;
                    color: $color-danger-font;
                }
            }

            &-aborted {
                .grid-column-status_label {
                    background: darken($color-danger, 10%);
                    color: #fff;
                }
            }

            &-accepted {
                .grid-column-status_label {
                    background: #5AAF75;
                    color: #fff;
                }
            }
        }
    }
}


.recordsList-back{
    .grid {
        .grid_body{
            .grid-row-titles {
                th{
                    a{
                        color:#666;
                    }
                }
            }
        }
    }

    .grid-search-title{
        i{
            transform: rotate(180deg);
            transition: all 0.3s ease-in-out;
        }
        .collapsed{
            i{
                margin-left: $default-margin;
                transform: rotate(0deg);
            }
        }
    }
}
