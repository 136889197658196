@mixin show-comment {
  background-color: #FFFFFF;
  border-radius: 15px;
  border: 1px solid #000000;
  display: block;
  padding: 15px 20px;
  position: absolute;
  right: 0;
  top:0;
  width: 500px;
  z-index: 9;

  @media (max-width: $screen-sm-max) {
    max-width: 80vw;
    white-space: normal !important;
  }
}

.internal-comment {
  position: relative;

  &__resume {
    font-style: italic;
    cursor: pointer;
  }

  &__text {
    display: none;

    &.show {
      @include show-comment;
    }
  }

  &:hover {
    .internal-comment__text {
      @include show-comment;
    }
  }
}
