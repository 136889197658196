%limited-content {
  width: 100%;
  max-width: var(--grid-max-width);
  margin: 0 var(--grid-margin);
  padding: 0 var(--grid-content-padding);
}

.limited-content {
  @extend %limited-content;
}

%full-content {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.full-content {
  @extend %full-content;
}

%center-cover {
  object-fit: cover;
  object-position: center;
}

.full-content--cover {
  @extend %full-content;
  @extend %center-cover;
}
