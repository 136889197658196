.select2-container {
    .select2-choice {
        height: 32px;
        line-height: 32px;
        background-image: none;

        .select2-arrow {
            background: transparent;
            border: 0;
        }
    }
}
