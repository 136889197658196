body.signin {
  .signin-form {
    padding: 19vh 0 265px;
    @include overlay();
    background: url('../images/background.png') no-repeat;
    background-size: cover;

    @include below(md) {
      padding: 60px 0 200px
    }

    @include below(xs) {
      padding: 60px 0 250px
    }

    > .errors {
      position: absolute;
      top: -70px;
      left: 50%;
      transform: translateX(-50%);
    }

    .contents {
      position: relative;
      padding: 0 20px;
      z-index: 2;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: $text-light-color;

      h2 {
        margin-top: 0;
        text-align: center;

        > span {
          display: block;
        }

        .title {
          text-transform: uppercase;
          font-size: 1.2rem;
        }

        .subtitle {
          margin-top: 7px;
          font-weight: bold;
          font-size: 2.6rem;
        }
      }

      form {
        width: 280px;
        max-width: 100%;
        margin: 0 auto;
        padding-top: 20px;

        .reset-password {
          display: block;
          font-size: 1rem;
          font-style: italic;
          color: $text-light-color;
        }
      }
    }

    .bottom-bar {
      z-index: 2;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: $primary-color;
      display: flex;
      justify-content: center;
      gap: 40px;
      padding: 50px 20px;

      @include below(xs) {
        flex-direction: column;

        > * {
          text-align: center;
        }
      }

      .text {
        color: $text-light-color;

        .title {
          text-transform: uppercase;
          font-size: 1rem;
        }

        .subtitle {
          font-weight: bold;
          font-size: 1.6rem;
        }
      }
    }
  }
}
