body.register {
  .register-form {
    flex-grow: 1;
    padding: 19vh 0 135px;
    @include overlay();
    background: url('../images/background.png') no-repeat;
    background-size: cover;

    @include below(xs) {
      padding: 60px 0 240px;
    }

    > .errors {
      position: absolute;
      top: -70px;
      left: 50%;
      transform: translateX(-50%);
    }

    .contents {
      position: relative;
      padding: 0 20px;
      z-index: 2;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: $text-light-color;

      h2 {
        margin-top: 0;
        text-align: center;

        > span {
          display: block;
        }

        .title {
          text-transform: uppercase;
          font-size: 1.2rem;
        }

        .subtitle {
          margin-top: 7px;
          font-weight: bold;
          font-size: 2.6rem;
        }
      }

      form {
        margin: 0 auto;
        padding: 20px 50px;
        gap: 0;

        @include below(sm) {
          padding: 20px 0px;
        }

        .row {
          > * {
            padding-left: 5px;
            padding-right: 5px;
          }
        }

        .mandatory-fields {
          text-align: right;
          font-size: 1rem;
          font-style: italic;
          margin-bottom: 15px;
        }

        .checkboxes {
          font-size: 0.9em;

          .has-error + .fa {
            color: $brand-danger;
          }

          > .fa {
            margin-top: 3px;
          }

          .form-group {
            display: inline-block;
            margin-bottom: 0;

            .checkbox {
              margin: 0;

              input {
                position: static;
                margin-left: 0;
                vertical-align: middle;
              }

              label {
                input {
                  margin-right: 5px;
                  margin-top: 0;
                }
              }
            }
          }
        }

        button[type="submit"] {
          margin-top: 30px;
        }
      }
    }

    .bottom-bar {
      z-index: 2;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: $primary-color;
      display: flex;
      justify-content: center;
      gap: 40px;
      padding: 50px 20px;

      @include below(xs) {
        flex-direction: column;

        > * {
          text-align: center;
        }
      }

      .text {
        color: $text-light-color;

        .title {
          text-transform: uppercase;
          font-size: 1rem;
        }

        .subtitle {
          font-weight: bold;
          font-size: 1.6rem;
        }
      }
    }
  }
}
