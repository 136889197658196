.alert {
    &.alert-success {
        background-color: rgba(83, 215, 105, 0.36);
        border: none;
        color: #4da251;
    }
}

.auth-base {
    .alert {
        &.alert-success {
            color: #9bffa0;
        }
    }
}
