/***
Page container
***/

.page-container {
  	margin: 0px;
  	padding: 0px;
  	position: relative;

  	@include clearfix();

  	/* Fixed header */
	.page-header-fixed & {
		margin-top: $header-height; 
	}	  

	/* Fixed footer for mobile */
	.page-footer-fixed.page-footer-fixed-mobile & {
		margin-bottom: 20px !important;
	}
}

@media (min-width: $screen-md-min) { 
	/* Page container in fixed footer */
	.page-footer-fixed .page-container {
	 	margin-bottom: 20px !important;
	}
}

@media (max-width: $screen-sm-max) { 
	
	/* Page container */
	.page-container {
		margin: 0 !important;
		padding: 0 !important;

		.page-header-fixed.page-header-fixed-mobile & {
			margin-top: $header-height !important;
		}
	}
}

@media (max-width: $screen-xs-min) {
	
	/* Page container */
	.page-container {  		
		.page-header-fixed.page-header-fixed-mobile & {
  			margin-top: $header-height-xs !important;
  		}
  	}
}