.page-content {
    padding-top: 35px !important;

    &.page-content-nomenu {
        margin-left: 0;
    }

    &-wrapper {
        .page-content-before {
            background: $primary-color;
            color: #fff;
            padding: $default-margin/4;
            position: fixed;
            width: calc(100% - 235px);
            z-index: 100;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }
}


@media (max-width: $screen-sm-max) {
    .page-content {

        &-wrapper {
            .page-content-before {
                width: 100%;
                position: static;
            }
        }
    }
}
