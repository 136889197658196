body.faq {
  background: $background-light-color;

  > .wrapper {
    padding: 0;

    .faq-wrapper {
      display: flex;
      gap: 110px;

      @include below(lg) {
        gap: 60px;
      }

      @include below(md) {
        flex-direction: column;
        gap: 50px;
      }

      > * {
        flex: 1;
      }

      @include above(md) {
        max-width: 1020px;
      }

      .faq-contents {
        flex: 1;
        margin-top: 19vh;

        @include below(md) {
          margin-top: 55px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          gap: 30px;
        }

        > .logo {
          img {
            width: 100px;
          }

          @include below(md) {
            flex-basis: 100%;
            text-align: center;

            img {
              width: auto;
            }
          }
        }

        .main {
          margin-top: 50px;

          .faq-entry {
            padding: 20px 0;
            border-top: 1px solid $shadow-color;

            &:last-child {
              border-bottom: 1px solid $shadow-color;
            }

            .question {
              font-weight: bold;
              display: flex;
              gap: 20px;
              align-items: flex-start;

              &:after {
                cursor: pointer;
                @include icon($icon-chevron);
                transform: rotate(90deg);
              }
            }

            .answer {
              margin: 20px 0 0 20px;
              display: none;
            }
          }
        }
      }

      .faq-titles {
        flex-grow: 1;
        padding: 19vh 0 135px;
        @include overlay();
        background: url('../images/background.png') no-repeat;
        background-size: cover;

        @include below(xs) {
          padding: 60px 0 240px;
        }

        > .errors {
          position: absolute;
          top: -70px;
          left: 50%;
          transform: translateX(-50%);
        }

        .contents {
          position: relative;
          padding: 0 45px;
          z-index: 2;
          display: flex;
          flex-direction: column;
          color: $text-light-color;

          @include below(sm) {
            padding: 0 20px;
          }

          @include below(xs) {
            padding: 0 10px;
          }

          .title {
            font-size: 1.7rem;
          }

          .subtitle {
            font-weight: bold;
            font-size: 2rem;
          }

          .description {
            margin-top: 20px;
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}
