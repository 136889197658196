.bg-default {
  background: $primary-color !important;
}

.bg-hover-default:hover {
  background: $primary-color !important;
}

.font-default {
  color: $primary-color !important;
}

.bg-font-default {
  color: #fff !important;
}

.border-default {
  border-color: $primary-color !important;
}

.border-top-default {
  border-top-color: $primary-color !important;
}

.border-bottom-default {
  border-bottom-color: $primary-color !important;
}

.border-left-default {
  border-left-color: $primary-color !important;
}

.border-right-default {
  border-right-color: $primary-color !important;
}

.bg-light {
  background: $primary-color-light !important;
}

.bg-hover-light:hover {
  background: $primary-color-light !important;
}

.font-light {
  color: $primary-color-light !important;
}

.bg-font-light {
  color: #fff !important;
}

.border-light {
  border-color: $primary-color-light !important;
}

.border-top-light {
  border-top-color: $primary-color-light !important;
}

.border-bottom-light {
  border-bottom-color: $primary-color-light !important;
}

.border-left-light {
  border-left-color: $primary-color-light !important;
}

.border-right-light {
  border-right-color: $primary-color-light !important;
}

.bg-super-light {
  background: $primary-color-super-light !important;
}

.bg-hover-super-light:hover {
  background: $primary-color-super-light !important;
}

.font-super-light {
  color: $primary-color-super-light !important;
}

.bg-font-super-light {
  color: #fff !important;
}

.border-super-light {
  border-color: $primary-color-super-light !important;
}

.border-top-super-light {
  border-top-color: $primary-color-super-light !important;
}

.border-bottom-super-light {
  border-bottom-color: $primary-color-super-light !important;
}

.border-left-super-light {
  border-left-color: $primary-color-super-light !important;
}

.border-right-super-light {
  border-right-color: $primary-color-super-light !important;
}

.bg-success {
  background: $color-success !important;
}
