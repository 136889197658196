html {
    height: 100%;
}

.minimalist {
    min-height: 100%;
    display: flex;
    flex-direction: column;

    header {
        min-height: 140px;
        height: auto;
        width: 100%;
        background: $bg-color;
        position: static;

        .container {

            &::after, &::before {
                content: none;
            }

            display: flex;
            align-items: center;
            justify-content: space-between;

            @media (max-width: $screen-sm-max) {
                flex-direction: column;
            }

            a {
                color: #fff;
            }

            .logo-link {
                display: block;

                .logo-default {
                    max-height: 140px;
                }
            }
        }
    }

    .wrapper {
        height: auto;
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        padding: $default-margin;
        background-size: cover;
        background-repeat: no-repeat;

        @media (max-width: $screen-sm-max) {
            padding-top: $default-margin / 4;
        }

        .simpleContainer {
            background: rgba(255, 255, 255, 0.8);
            padding: $default-margin;

            h1 {
                margin-top: 0;
                margin-bottom: 20px;
            }
        }
    }
}
