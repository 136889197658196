.propertyBlock {
    position: relative;

    &-title, &-action {
        margin: 10px 15px 20px;
        display: inline-block;
    }

    &-title {
        font-size: 18px;
        font-weight: 600;
    }

    &-action {
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 0;

        @media (max-width: $screen-sm-max) {
            padding: 5px 0;
            margin-bottom:15px;
            width: calc(100% - 30px)
        }
    }

    &-content {
        margin-left: 15px;
        margin-right: 40px;
        @media (max-width: $screen-sm-max) {
            margin-right: 15px;
        }

        .form-group {
            min-width: 100%;
            display: block;
        }

        &-picture {
            width: 100%;
            max-width: 290px;
            height: 168px;
            float: left;
            vertical-align: top;
            object-fit: cover;

            @media (max-width: 1200px) {
                float: none;
                margin: auto auto $default-margin;
                display: block;
            }
        }

        &-propertyVisited {
            clear: both;
            padding-top: 3px;

            label {
                &.propertyVisited {
                    font-weight: bold;
                    color: $primary-color;
                    float: left;
                    margin-top: 11px;
                    margin-right: 20px;
                }
            }
        }

        &-infos {
            width: calc(100% - 350px);
            margin-left: 350px !important;

            @media (max-width: 1200px) {
                margin-left: 0 !important;
                width: 100%;
            }

            tr {

                td, th {
                    height: auto;
                    border-top: 0 !important;
                    border-bottom: 1px solid $panel-default-border;
                    padding: 4px !important;
                }
            }

            &-list{
                margin: 0 ;
                width: calc(50% - 175px);
                float: left;
                padding:0;
                font-size:14px;
                list-style: none;
                &.first{
                    margin-left:60px;
                }
                li{
                    padding : 5px 10px 5px 0;
                    border-bottom: 1px solid $panel-default-border;

                }

                @media (max-width: 1200px) {
                    width:50%;
                    &.first{
                        clear:both;
                        margin-left:0;
                    }
                }

                @media (max-width: 768px) {
                    width:100%;
                    &:last-child{
                        li{
                            &:last-child {
                                border-bottom: none;
                            }
                        }
                    }
                }
            }

        }
    }
}
