//
// Mixins
// Based on Twitter Bootstrap's _mixings.scss

@mixin clearfix() {
  &:before,
  &:after {
    content: " "; // 1
    display: table; // 2
  }
  &:after {
    clear: both;
  }
}

// Opacity

@mixin opacity($value, $important: '') {
  opacity: $value #{$important};
  filter: alpha(opacity=#{$value * 100}) #{$important};
}

// Border radius.

@mixin border-radius($radius, $important: '') {
  -webkit-border-radius: $radius#{$important};
  -moz-border-radius: $radius#{$important};
  -ms-border-radius: $radius#{$important};  
  -o-border-radius: $radius#{$important};      
  border-radius: $radius#{$important}; 
}

// Placeholder text
// -------------------------
@mixin placeholder($color: $input-color-placeholder) {
  &::-moz-placeholder           { color: $color; opacity: 1; } // See https://github.com/twbs/bootstrap/pull/11526
  &:-ms-input-placeholder       { color: $color; } // Internet Explorer 10+
  &::-webkit-input-placeholder  { color: $color; } // Safari and Chrome
}

// Drop shadows
// By default set to: none
@mixin box-shadow($shadow) {
  -webkit-box-shadow: none; // iOS <4.3 & Android <4.1
          box-shadow: none;
}

// Labels
// -------------------------
@mixin label-variant($color) {
  background-color: $color;
  &[href] {
    &:hover,
    &:focus {
      background-color: darken($color, 10%);
    }
  }
}

@mixin  ease-out($duration: 450ms, $property: all, $delay: 0ms) {
  transition: $property $duration cubic-bezier(0.23, 1, 0.32, 1) $delay;
}

@mixin  pulsate($animation-name, $start-size: 0.75, $end-size: 1, $duration: 1.5s) {
  @keyframes "#{$animation-name}" {
    0%, 100% {
      transform: scale($start-size);
    }
    50% {
      transform: scale($end-size);
    }
  }  
  animation: $animation-name $duration ease 0s infinite;
}

/* Cubic Bezier Transition */
@mixin cubic-transition ($delay, $duration, $property) {
    transition: {
        duration: $duration;
        property: $property;
        timing-function: cubic-bezier(0.7, 1, 0.7, 1);
    } 
}

@mixin background-opacity($color, $opacity: 0.3) {
    background: rgba($color, $opacity);
}