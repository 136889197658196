:root {
  --grid-col-width: 84px;
  --grid-gutter: 16px;
  --grid-max-width: 1600px;
  --grid-margin: auto;
  --grid-content-padding: 18px;
  --menu-height: auto;
  --header-height: 100px;
  --header-padding: 40px;
  --layout-spacing: 70px;
  --paragraph-spacing: 70px;
  --paragraph-small-spacing: 25px;

  @include below(full-hd) {
    --grid-max-width: 1400px;
  }

  @include below(xxl) {
    --grid-max-width: 1220px;
  }

  @include below(lg) {
    --header-height: 82px;
  }

  @include below(md) {
    --grid-gutter: 8px;
    --grid-margin: 37px;
    --grid-max-width: calc(100% - (var(--grid-margin) * 2));
    --menu-height: 100px;
    --layout-spacing: 30px;
    --paragraph-spacing: 30px;
    --paragraph-small-spacing: 25px;
  }

  @include below(sm) {
    --grid-margin: 24px;
    --grid-content-padding: 0;
  }
}

.mobile, .is-mobile {
  display: none;

  @include below(md) {
    display: block;
  }
}

.is-desktop {
  @include below(md) {
    display: none;
  }
}
