@import './form/_transparent.scss';

input[type='number'] {
    -moz-appearance:textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}


input.form-control, textarea.form-control {
    &:focus {
        border-color: #c2cad8;
    }
}

textarea {
    max-width: 100%;
    min-width: 100%;
}

div.radio {
    display: inline-block;
    margin-right: 15px;

    label {
        div.radio {
            margin: 0;
        }
    }


    label{
        padding-left: 30px;
        cursor: pointer;
        position: relative;
        &:before, &:after{
            content: '';
            position: absolute;
            top: 50%;
            border-radius: 50%;
            z-index:10;
        }
        &:before{
            left:0;
            margin-top:-8px;
            width: 16px;
            height: 16px;
            border: 1px solid  $brand-main-font-color;
            background: transparent;
        }
        &:after{
            left: 0px;
            margin-top:-8px;
            width: 16px;
            height: 16px;
            background-image: radial-gradient(white 30%, $primary-color 50%, $bg-default-hover 100%);
            opacity: 0;
            transform: scale(0);
            transition: all .5s ease-in-out;
        }
    }
    input{
        position: absolute;
        left: -9999px;
    }
    input[type="radio"]:checked + label:after {
        opacity: 1;
        transform: scale(1);
    }
}

.input-group {

    &--right {
        input {
            border-right: 0;
        }

        .input-group-addon {
            border-left: 0;
        }
    }

    .input-group-addon {
        .glyphicon-question-sign, .fa-question-circle-o {
            cursor: help;
        }
    }

    &.has-success {
        .input-group-addon {
            background-color: #fff;
        }
    }
}

.help-block {
    color: $color-danger-font;
}

.form-group {
    &.has-error {
        .checkbox {
            label {
                color: #ed6b75;
                font-weight: 600;
            }
        }
    }
}

.field-inline {
    display: flex;

    .form-group {
        width: 50%;

        &:first-child {
            margin-right: 30px;
        }
    }
}
